<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-pesquisar-ocorrencia"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title>
          Pesquisar ocorrência
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-7">
        <v-row>
          <v-col cols="12">
            <autocomplete-ocorrencias
              limpar-apos-selecionar
              @change="$emit('change', $event)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {

    components: {
      AutocompleteOcorrencias: () => import('@/components/input/InputAutocompleteOcorrencias'),
    },

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {

      }
    },

    methods: {

    },
  }
</script>

<style lang="css">
  .dialog-pesquisar-ocorrencia {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .dialog-pesquisar-ocorrencia {
      width: 50% !important;
    }
  }
</style>
